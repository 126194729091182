
.methodsPage {

    .methodsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--big-gap);
    }

    .methodContainer {
        color: var(--on-surface);

        display: flex;
        justify-content: center;
        align-items: center;

        gap: var(--big-gap);
    }

    .methodInfoContainer {
        border-left: var(--border);
        padding-left: var(--big-gap);
    }
}