.bookConsultationForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--big-gap);

    .alternativeBookingMethodContainer {
        border-bottom: var(--border);
        padding-bottom: var(--medium-gap);
        display: flex;
        flex-direction: column;
        gap: var(--medium-gap);
    }

    .inputField.time {
        display: none;
    }

    .inputField.time.revealed {
        display: block;
    }

    .timeInputPopUpDialog {
        min-width: 300px;
    }

    .datePicker {
        width: 300px;
    }

    .react-datepicker__time-list-item--disabled {
        display: none;
    }

    .react-datepicker__time-list-item {
        list-style: none;
    }

    .react-datepicker {
        border: var(--border)!important;
        border-radius: 0!important;
    }

    .react-datepicker__time-container {
        width: 200px!important;
    }

    .react-datepicker__time-box {
        width: 100%!important;
    }

    .react-datepicker__time-list {
        width: 200px!important;
    }

    .react-datepicker__time-list-item {
        width: 200px!important;
    }
}
