.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: var(--border);

    .footerMenuContainer {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: var(--small-gap);
    }
    
    .footerMenuItem {
        list-style: none;
    }
    
    .footerMenuLink {
        text-decoration: none;
        position: relative;
    }
    
    /* Link hover underline animation */
    
    .footerMenuLink::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 0;
        height: 100%;
        border-bottom: var(--border);
        transition: var(--transition);
    }
    
    .footerMenuLink:hover::after {
        width: 100%;
    }
    
    .footerContactsContainer {
        display: flex;
        justify-content: space-between;
    }
    
    .allRightsReservedContainer {
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .footer {

        .footerMenuContainer {
            /* display: none; */
        }

        .footerContactsContainer {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }

        .footerMenuItem:has(:not(.bookingMenuLink)) {
            display: none;
        }
    }
}

