.homePage {

    .heroSection {

        height: calc(100vh - var(--header-height));

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        row-gap: var(--big-gap);
        justify-items: center;
        align-items: center;

        .heroInfoContainer {
            grid-column: 1 / 2;
            grid-row: 1 / 2;

            align-self: end;
    
            h1 {
                padding: 0;
            }
        }

        .heroButtonContainer {

            align-self: start;

            grid-column: 1 / 2;
            grid-row: 2 / 3;

            .buttonLink {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                gap: var(--small-gap);
                position: relative;
                text-decoration: none;
            }

            .button {
                width: max-content;
                padding: var(--medium-gap);
                color: var(--on-surface);
            }

            .arrowIconSVG {
                position: relative;
                width: 50px;
                height: 25px;
                animation-name: heroArrowIconAnimation;
                animation-duration: 1.5s;
                animation-iteration-count: infinite;
                animation-timing-function: ease-it;
                animation-direction: alternate;
            }
            
            .arrowIconPath {
                stroke: var(--outline);
                stroke-width: 1px;
                fill: none;
            }
        }

        .heroPhotoContainer {
            grid-column: 2 / 3;
            grid-row: 1 / 3;
            max-width: 350px;
            position: relative;
            display: flex;

            .heroPhoto {
                width: 100%;
                z-index: 1;
                position: relative;
            }
    
            .heroPhotoOutline {
                border: var(--border);
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 0;
                transition: var(--transition-slow);
                top: 0;
                left: 0;
            }
    
            .heroPhotoOutlineAfterLoad {
                top: 14px;
                left: 14px;
            }
    
            .heroPhotoContainer:hover .heroPhotoOutline {
                top: 24px;
                left: 24px;
            }
        }
    }

    .problemsSection {

        .problemsContainer {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: var(--big-gap);
        }

        .problemContainer {
            width: 200px;
            height: 200px;
            
            background-size: 250%;
            
            animation: backgroundAnimationVariation1 300s ease-in-out infinite alternate;

            /* border: var(--border); */
            overflow: hidden;
        }

        .problemContentContainer {
            color: white;
            text-align: center;
            
            display: flex;
            align-items: center;
            justify-content: center;
            
            padding: var(--medium-gap);

            width: 100%;
            height: 100%;

            backdrop-filter: grayscale(100%) brightness(70%);
            transition: var(--transition);

            z-index: 1;
        }


        .problemContentContainer:hover {
            backdrop-filter: grayscale(30%) brightness(80%);
        }

        .problemContainer.irritation {
            background-image: url("../images/desert.jpeg");
            animation-direction: alternate-reverse;
            border-radius: 49% 51% 40% 60% / 51% 33% 67% 49%;
        }

        .problemContainer.selfRespect {
            background-image: url("../images/waves.jpeg");
            animation-direction: alternate-reverse;
            border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
        }

        .problemContainer.depression {
            background-image: url("../images/desert-2.jpeg");
            animation-name: backgroundAnimationVariation2;
            border-radius: 47% 53% 70% 30% / 78% 34% 66% 22%;
        }

        .problemContainer.perfectionism {
            background-image: url("../images/stars.jpeg");
            border-radius: 19% 81% 54% 46% / 31% 23% 77% 69%;
        }
        .problemContainer.selfDissatisfaction {
            background-image: url("../images/forest.jpeg");
            animation-name: backgroundAnimationVariation2;
            border-radius: 33% 67% 53% 47% / 63% 47% 53% 37%;
        }

        .problemContainer.negativeFeelings {
            background-image: url("../images/rocks.jpeg");
            animation-name: backgroundAnimationVariation2;
            animation-direction: alternate-reverse;
            border-radius: 22% 78% 50% 50% / 45% 42% 58% 55%;
        }
        .problemContainer.communicationProblems {
            background-image: url("../images/clouds.jpeg");
            border-radius: 60% 40% 38% 62% / 47% 41% 59% 53%;
        }
        .problemContainer.relationshipProblems {
            background-image: url("../images/winter.jpeg");
            animation-direction: alternate-reverse;
            border-radius: 65% 35% 21% 79% / 55% 64% 36% 45%;
        }
        .problemContainer.loss {
            background-image: url("../images/forest-2.jpeg");
            border-radius: 62% 38% 37% 63% / 34% 51% 49% 66%;
        }
    }

    .methodsSection {

        .methodsContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: var(--big-gap);
        }

        .methodContainer {
            color: var(--on-surface);

            display: flex;
            justify-content: center;
            align-items: center;

            gap: var(--big-gap);
        }

        .methodInfoContainer {
            border-left: var(--border);
            padding-left: var(--big-gap);
        }
    }

    .educationSection {
        .educationsContainer {
            display: grid;
            grid-template-columns: 1fr 1fr;    
            gap: var(--big-gap);
        }
    }
 
    .formatSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--big-gap);

        .formatIcon {
            font-size: 200px;
            float: left;
        }
    }

    .afterSection {

        .afterInfoContainer {
            display: flex;
            flex-direction: column;
    
            .afterInfoItem {
                max-width: 600px;
                padding: 64px;
                background-color: var(--surface);
                color: var(--on-surface);
        
                &:nth-of-type(1) {
                    border-radius: 44% 56% 41% 59% / 36% 44% 56% 64% ;
                }
        
                &:nth-of-type(2) {
                    border-radius: 35% 65% 39% 61% / 51% 41% 59% 49% ;
                }
        
                &:nth-of-type(3) {
                    border-radius: 61% 39% 54% 46% / 73% 24% 76% 27% ;
                }
        
                &:nth-of-type(2n) {
                    align-self: end;
                }
            }
        }
    }

    .reviewsSection {
        display: grid;
        place-items: center;

        .reviewsContainer {
            max-width: min(100vw, 800px);
            columns: 2;
            column-gap: var(--big-gap);
        }

        .reviewContainer {
            break-inside: avoid;
            margin-bottom: var(--big-gap);
            transition: var(--transition);
        }

        .reviewContainer:hover {
            transform: scale(1.04);
        }

        .reviewImage {
            width: 100%;
        }
    }

    .aboutSection {

        .aboutContainer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto;
            
            column-gap: var(--big-gap);
        }

        .aboutImageContainer {
            grid-row: 1 / 3;
            grid-column: 1 / 2;
            margin: calc(var(--big-gap) * 2);
            position: relative;
            justify-self: center;
            align-self: center;

            &:hover .aboutImageOutline  {
                top: 24px;
                left: -24px;
            }

            .aboutImage {
                max-width: 100%;
                max-height: 100%;
                position: relative;
                z-index: 1;
            }

            .aboutImageOutline {
                border: var(--border);
                width: 100%;
                height: 100%;
                position: absolute;
                transition: var(--transition);
                top: 14px;
                left: -14px;
                z-index: 0;
            }
  
        }

        .aboutInfoContainer1 {
            grid-column: 2 / 3;
            grid-template-rows: 1 / 2;
            align-self: end;
        }
        
        .aboutInfoContainer2 {
            grid-column: 2 / 3;
            grid-template-rows: 2 / 3;
            align-self: start;
        }
    }

    .blogSection {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: var(--small-gap);

            .blogPostsContainer {
                display: flex;
                justify-content: center;
                gap: var(--small-gap);
                flex-wrap: wrap;
            }

            .blogPostContainer {
                width: 280px;
                height: 280px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: 1px solid black;
            }

            .blogPostIcon {
                font-size: 100px;
            }

            .blogPostName {
                text-align: center;
            }

            .bookSection {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .bookForm {
                width: 300px;
                height: 300px;
                border: 1px solid black;
            }
    }
    
}

@media only screen and (max-width: 768px) {

    .homePage {

        .heroSection {
            grid-template-columns: 1fr;
            grid-template-rows: auto 1fr auto;
            height: auto;

            .heroInfoContainer {

            }

            .heroPhotoContainer {
                grid-column: 1 / 2;
                grid-row: 2 / 3;
            }

            .heroButtonContainer {
                grid-row: 3 / 4;
            }
        }

        .reviewsSection {

            .reviewsContainer {
                columns: 1;
            }

        }

        .afterSection {

            .afterInfoContainer {
                gap: var(--big-gap);
            }
        }

        .aboutSection {

            .aboutContainer {
                grid-template-columns: 1fr;
            }

            .aboutImageContainer {
                grid-row: 2 / 3;
                margin: 0 0 var(--big-gap) 0;

            }

            .aboutInfoContainer1 {
                grid-column: 1 / 2;
            }

            .aboutInfoContainer2 {
                grid-column: 1 / 2;
                grid-row: 3 / 4;
            }
        }

    }
}

@keyframes heroArrowIconAnimation {
    0% {
        top: 0px;
    }

    100% {
        top: 10px;
    }
}

@keyframes backgroundAnimationVariation1 {
    0% {
        background-position: 0% 0%;
    }
    25% {
        background-position: 100% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    75% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

@keyframes backgroundAnimationVariation2 {
    0% {
        background-position: 0% 0%;
    }
    25% {
        background-position: 50% 100%;
    }
    50% {
        background-position: 100% 0%;
    }
    75% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 25%;
    }
}

@keyframes backgroundAnimationVariation3 {
    0% {
        background-position: 0% 0%;
    }
    25% {
        background-position: 100% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    75% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}
