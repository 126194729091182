.contactPage {

    .contactSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--big-gap);
    }

    .dividerLine {
        width: min(100%, 500px);
        border-top: var(--border);
        height: 1px;
    }
}