.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--header-height);
    border-bottom: var(--border);

    position: sticky;
    top: 0;

    z-index: 2;

    .logoContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logoLink {
        font-family: var(--font-family-1);
        font-size: 22px;
        text-decoration: none;
    }

    .headerMenuContainer {
        display: flex;
        align-items: center;
        gap: var(--small-gap);
    }

    .headerMenuItem {
        list-style: none;
    }

    .headerMenuItem.visibleForMobileOnly {
        display: none;
    }

    .headerMenuLink {
        text-decoration: none;
        position: relative;
    }

    .headerMenuLink.active {
        font-weight: 600;
    }

    /* Link hover underline animation */

    .headerMenuLink::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 0;
        height: 100%;
        border-bottom: var(--border);
        transition: var(--transition);
    }

    .headerMenuLink:hover::after {
        width: 100%;
    }

    .button {
        width: 209px;
    }
    
    .hamburgerContainer {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .header {

        .headerMenuContainer {
            display: none;
        }
        
        /* Reveal menu on hamburger click */
        &:has(.hamburgerCheckbox:checked) .headerMenuContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100vw;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background-color: var(--background);
        }
        
        &:has(.hamburgerCheckbox:checked) .headerMenuContainer .headerMenuLink {
            font-size: 34px;
        }

        .headerMenuItem.visibleForMobileOnly {
            display: block;
        }

        .button {
            display: none;
        }

        .hamburgerContainer {
            --hamburgerWidth: 50px;
            --hamburgerHeight: 25px;
            --hamburgerLineColor: var(--outline);
            --hamburgerTransition: var(--transition);
            display: block;
            z-index: 3;
            
            .hamburgerIcon {
                height: var(--hamburgerHeight);
                width: var(--hamburgerWidth);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
    
                .hamburgerLine {
                    background: var(--hamburgerLineColor);
                    display: block;
                    height: 1px;
                    width: var(--hamburgerWidth);
                    transition: var(--hamburgerTransition);
                }
            }
            .hamburgerCheckbox {
                display: none;
                &:checked ~ #headerMenu {
                    max-height: none;
                    height: 90vh;
                    position: absolute;
                    top: 10vh;
                    left: 0;
                    width: 100vw;
                    display: flex;
                    justify-content: center;
                    background-image: linear-gradient(white,#bbb5c3);
                }
                &:checked ~ .hamburgerIcon .hamburgerLine {
                    &.two {
                        background: transparent;
                    }
                    &.one {
                        transform: translateY(calc(var(--hamburgerHeight) / 2 - 1px)) rotate(45deg);
                    }
                    &.three {
                        transform: translateY(calc(var(--hamburgerHeight) / 2 * (-1))) rotate(-45deg);
                    }
                }
            }
        }
    }
}

body:has(.hamburgerCheckbox:checked) {
    overflow: hidden;
}