
.processesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--big-gap);
    position: relative;

    .processContainerSVG {
        position: absolute;
        left: -50px;
        top: 0px;
        height: 100%;
        width: 3px;
    }

    .myPath {
        stroke-width: 1px;
        stroke: var(--outline);
    }

    .processContainer {
        width: min(500px, calc(100vw - var(--side-padding-for-sections) * 2));
        border: var(--border);
        padding: var(--medium-gap);
        background-color: var(--surface);
        color: var(--on-surface);
        z-index: 1;
    }

    .processLine {
        height: 100px;
        width: 1px;
        background-color: var(--outline);
        position: absolute;
        left: -50px;
        top: 0;
    }
}

@media only screen and (max-width: 768px) {
    
    .processesContainer {

        .processContainerSVG {
            left: calc(50vw - var(--side-padding-for-sections));
        }

    }
}