.aboutPage {
    
    .infoContainer {
        background-color: var(--surface);
        border: var(--border);
        padding: var(--big-gap);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: var(--big-gap);
    }

    .generalInfoContainer {

        .generalInfoTextContainer {
            flex-basis: 500px;
            flex-grow: 2;
        }
        
        .generalInfoImageContainer {
            flex-basis: min(300px, 100%);
            flex-grow: 1;
            
            .generalInfoImage {
                width: 100%;
                border: var(--border);
            }
        }
    }

    .personalLifeContainer {

        .personalInfoTextContainer {
            flex-basis: 500px;
            flex-grow: 2;
        }

        .personalInfoImageContainer {
            flex-basis: min(300px, 100%);
            flex-grow: 1;

            .personalInfoImage {
                max-width: 100%;
            }
        }
    }


}